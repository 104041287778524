"use client";
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
/**
 *  @Author: qp
 *  @Date: 2023-11-01 17:41:05
 * @Last Modified by: qp
 * @Last Modified time: 2024-01-11 19:31:22
 * @name 高阶弹层
 * @todo 无需调用方控制状态
 * @description 高阶组件；第一次调用传入弹窗内组件，返回新组件；新组建接收子组件，点击子组件自动唤起弹窗；
 **/

import { Popup } from "antd-mobile";
import React, { useState, useEffect } from "react";
import { shebeiType } from "@/utils/utils";

const Mypopup =
  (Component, modalProps) =>
  ({
    children,
    extra = null,
    show = false,
    onVisibleChange = null,
    ...rest
  }) => {
    const [visible, setVisible] = useState(show);
    const [height, setHeight] = useState("100vh");
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
      setVisible(show);
    }, [show]);
    useEffect(() => {
      if (typeof onVisibleChange === "function") {
        onVisibleChange(visible);
      }
    }, [onVisibleChange, visible]);

    useEffect(() => {
      if (typeof window !== "undefined") {
        setHeight(window.innerHeight);
        setIsIOS(shebeiType() === "ios");
      }
    }, []);

    const child = React.Children.only(children);
    const newChildProps = {
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (child.props.onClick) {
          child.props.onClick(e);
        }
        setVisible(!visible);
      },
      extra: extra || null,
    };
    const newChild = React.cloneElement(child, newChildProps);

    return (
      <>
        {newChild}
        <Popup
          onClose={() => setVisible(false)}
          onMaskClick={() => setVisible(false)}
          visible={visible}
          style={{
            "--z-index": 999,
          }}
          destroyOnClose
          {...modalProps}
        >
          <div style={{ height: isIOS ? height : "auto" }}>
            <Component close={() => setVisible(false)} {...rest} />
          </div>
        </Popup>
      </>
    );
  };

export default Mypopup;
