/**
 *  @Author: qp
 *  @Date: 2023-11-24 10:12:47
 * @Last Modified by: qp
 * @Last Modified time: 2023-12-27 14:29:32
 *  @name 验证码
 **/
"use client";
import s from "./Code.module.css";
import { verifyMobile } from "./Mobile";
import { useState, useEffect, useRef } from "react";
import { Toast } from "antd-mobile";
import fetch from "@/utils/fetch";

const getCode = (mobile) =>
  fetch(`/c-usercenter-consumer/user/getCode?mobile=${mobile}`, {
    needCode: true,
  });

const Code = ({ value = "", onChange, mobile }) => {
  const [timer, setTimer] = useState(60);
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef();
  const handleFocus = () => {
    setIsFocus(true);
    document.getElementById("close1").style.display = "none";
    document.getElementById("close2").style.display = "block";
  };
  const handleBlur = () => {
    //setIsFocus(false);
  };

  useEffect(() => {
    if (timer !== 60) {
      setTimeout(() => {
        if (timer === 0) {
          setTimer(60);
        } else {
          setTimer(timer - 1);
        }
      }, 1000);
    }
  }, [timer]);

  const handleCode = () => {
    if (verifyMobile(mobile)) {
      getCode(mobile).then((res) => {
        if (res.success) {
          setTimer(timer - 1);
        } else {
          Toast.show({
            content: res.message,
          });
        }
      });
    } else {
      Toast.show({
        content: "请输入正确的手机号",
      });
    }
  };

  return (
    <>
      <div className={s.code}>
        <div className={s.left}>
          <input
            type="tel"
            placeholder="请输入验证码"
            maxLength={6}
            value={value}
            onChange={(e) => {
              if (e.target.value.length > 6) return;
              onChange(e.target.value);
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />

          <div
            id="close2"
            style={{ display: isFocus ? "block" : "none" }}
            className={s.close}
            onClick={() => {
              onChange("");
            }}
          ></div>
        </div>
        <div className={s.codeBtn}>
          {timer === 60 ? (
            <span onClick={handleCode}>获取验证码</span>
          ) : (
            <span className={s.timer}>{timer}s后重新获取</span>
          )}
        </div>
      </div>
      {/* <div className={s.line}></div> */}
    </>
  );
};

export default Code;
