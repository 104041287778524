/**
 *  @Author: qp
 *  @Date: 2023-11-24 09:58:04
 * @Last Modified by: qp
 * @Last Modified time: 2023-12-07 16:09:18
 *  @name 复选框
 **/

import s from "./Checkbox.module.css";

const Checkbox = ({ children, value, onChange, className }) => {
  return (
    <div className={`${s.xieyi} ${className}`}>
      <div
        className={`${s.checkbox} ${value && s.checked}`}
        onClick={() => onChange(!value)}
      ></div>
      <div className={s.text}>{children}</div>
    </div>
  );
};

export default Checkbox;
