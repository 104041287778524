"use client";
import { useState } from "react";
import { Toast } from "antd-mobile";
import s from "./PhoneForm.module.css";
import Checkbox from "./Checkbox";
import Mobile, { verifyMobile } from "./Mobile";
import Code from "./Code";
import { useMemo } from "react";

const PhoneForm = ({
  children,
  onSubmit,
  title,
  agreement,
  submit,
  initialMobile = "",
  justSubmit,
  extend,
}) => {
  const dispalyInitialMobile = initialMobile.replace(
    /(\d{3})\d{4}(\d{4})/,
    "$1****$2"
  );
  const [phone, setPhone] = useState(dispalyInitialMobile);
  const [code, setCode] = useState("");
  // const [checked, setChecked] = useState(!!dispalyInitialMobile);
  const [checked, setChecked] = useState(true);

  const noVerify = useMemo(
    () => phone && (phone === initialMobile || phone === dispalyInitialMobile),
    [phone, initialMobile, dispalyInitialMobile]
  );

  const handleLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!justSubmit) {
      if (!noVerify) {
        if (!verifyMobile(phone)) {
          Toast.show({
            content: "请输入正确的手机号",
          });
          return false;
        }
        if (!code) {
          Toast.show({
            content: "请输入正确的验证码",
          });
          return false;
        }
      }
    }
    if (!checked) {
      Toast.show({
        content: "请选中已阅读并同意协议",
      });
      return false;
    }
    if (!noVerify) {
      if (onSubmit) onSubmit(phone, code);
      return true;
    }

    if (onSubmit) onSubmit(initialMobile);
    return true;
  };

  return (
    <div className={s.login}>
      <div className={s.title}>{title}</div>
      {children}
      {!justSubmit && <Mobile value={phone} onChnage={setPhone}></Mobile>}
      {!justSubmit && !noVerify && (
        <Code value={code} onChange={setCode} mobile={phone}></Code>
      )}
      {extend}
      <div className={s.btn} onClick={handleLogin}>
        {submit}
      </div>
      <Checkbox value={checked} onChange={setChecked}>
        {agreement}
      </Checkbox>
    </div>
  );
};

export default PhoneForm;
