"use client";
import PhoneForm from "./PhoneForm";
import Link from "@/components/Link";
import Popup from "@/components/Popup";
import useUser from "@/hooks/useUser";
import { useEffect, useState } from "react";
import { shebeiType } from "@/utils/utils";

const _Login = ({ onSubmit, close }) => {
  const [height, setHeight] = useState("100vh");
  const [isIOS, setIsIOS] = useState(false);
  const { login } = useUser();
  const handleLogin = async (mobile, code) => {
    const user = await login(mobile, code);
    if (user?.islogin) {
      if (onSubmit) onSubmit(user);
      if (close) close();
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHeight(window.innerHeight);
      setIsIOS(shebeiType() === "ios");
    }
  }, []);

  console.info(isIOS);

  return (
    <div style={{ height: isIOS ? height : "auto" }}>
      <PhoneForm
        submit="登录"
        title="登录"
        onSubmit={handleLogin}
        agreement={
          <>
            已阅读并同意
            <Link href="https://static.taocheche.com/taoche-h5/serve/serve1/index.html">
              《网络服务协议》
            </Link>
            与
            <Link href="https://m.taocheche.com/about/notice.html">
              《隐私政策》
            </Link>
            并使用本机号码登录
          </>
        }
      ></PhoneForm>
    </div>
  );
};

export const Login = _Login;

const PopupLogin = Popup(_Login, {
  showCloseButton: true,
});

export default ({ onSubmit, children }) => {
  const { user } = useUser();
  if (user && user?.islogin) {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(user);
        }}
      >
        {children}
      </div>
    );
  }

  return <PopupLogin onSubmit={onSubmit}>{children}</PopupLogin>;
};
