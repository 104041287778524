/**
 *  @Author: qp
 *  @Date: 2023-11-24 10:03:26
 * @Last Modified by: qp
 * @Last Modified time: 2023-12-16 18:01:23
 *  @name 手机号
 **/

import { useRef, useState } from "react";
import s from "./Mobile.module.css";

export const verifyMobile = (mobile) => /^1\d{10}$/.test(mobile);

const Mobile = ({ value = "", onChnage }) => {
  // const [mobile, setMobile] = useState(value);
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef();

  const handleFocus = () => {
    setIsFocus(true);
    document.getElementById('close1').style.display= 'block';
    document.getElementById('close2').style.display= 'none';

  };

  const handleBlur = () => {
    //setIsFocus(false);
    // if (!value && value === initialValue) {
    //   setMobile(initialValue.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2"));
    // }
  };

  return (
    <>
      <div className={s.phone}>
        <input
          ref={inputRef}
          type="tel"
          placeholder="请输入手机号"
          maxLength={11}
          value={value}
          onChange={(e) => onChnage(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div
          id="close1"
          style={{ display:isFocus ? "block" : "none" }}
          className={s.close}
          onClick={() => {
            onChnage("");
            inputRef.current.focus();
          }}
        ></div>
      </div>
      {/* <div className={s.line}></div> */}
    </>
  );
};

export default Mobile;
